import {defineStore} from 'pinia'

interface UseSearchResultState {
    spaceList: SpaceList[]
    requestId: string
    total: number
}

interface SpaceList {
    list: []
    request_id: string
    total: number
}


export const useSearchResultStore = defineStore('searchResult', {
    state: (): UseSearchResultState => ({
        spaceList: [],
        requestId: '',
        total: 0
    }),
    getters: {
        getSpaceList: (state) => {
            return state.spaceList
        },
        getRequestId: (state) => {
            return state.requestId
        },
        getSpaceListTotal: (state) => {
            return state.total
        }
    },
    actions: {
        setSpaceList(spaceList: any[]): void {
            this.spaceList = spaceList
        },
        setRequestId(Id: string): void {
            this.requestId = Id
        },
        setSpaceListTotal(total: number): void {
            this.total = total
        }
    },
    persist: {
        storage: persistedState.sessionStorage
    }
})

